.react-calendar {
	width: 100% !important;
	border: none !important;
	font-family: 'Source Sans Pro', Roboto, 'Helvetica Neue', sans-serif !important;
}

.react-calendar__tile {
	padding: 5px 5px !important;
}

.react-calendar__tile abbr {
	border: 1px solid transparent;
	min-width: 32px;
	width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	min-height: 32px;
	border-radius: 32px;
}

.react-calendar__month-view__days__day--weekend {
	color: #47596a !important; /* #d10000; */
}

.react-calendar__month-view__weekdays__weekday abbr {
	text-decoration: none;
}

.react-calendar__tile:disabled {
	background-color: white !important; /*#f0f0f0; */
	color: #ccc !important;
}

.react-calendar__tile--now {
	/* background: #ffff76; */
	background: none !important;
	margin: 0 auto;
}

.react-calendar__tile--now abbr {
	border: 1px solid #bbb !important;
	background: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #ffffa9;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: white !important; /* #e6e6e6; */
}

.react-calendar__tile:enabled:hover abbr {
	border-color: #0066cc;
	background-color: #f0f6fc;
	color: #0066cc;
}

.react-calendar__tile:enabled:focus abbr {
	border-color: #0066cc;
	background-color: #0066cc;
	color: white;
}

.react-calendar__tile--active {
	background: white !important;
}

.react-calendar__tile--active abbr {
	background: #006edc;
	border-color: #006edc;
	color: white;
}
